import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const EndfieldGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page endfield'} game="endfield">
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/endfield/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Guides</h1>
          <h2>
            Best guides for Arknights: Endfield that will help you understand
            the game better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Arknights: Endfield Database is currently being created!</h4>
          <p>
            We're gathering information on the current CBT now and the site will
            be updated soon!
          </p>
        </div>
      </div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the Game"
          link="/arknights-endfield/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/arknights-endfield/guides/reroll-guide"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_reroll.jpg"
              alt="Reroll Guide"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Beginner Guide"
          link="/arknights-endfield/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_beginner.jpg"
              alt="Tier List"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Free Characters"
          link="/arknights-endfield/guides/free-characters"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_free.jpg"
              alt="Free Characters"
            />
          }
          isWIP
        />
      </Row>
      <SectionHeader title="Generic Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Character Progression"
          link="/arknights-endfield/guides/character-progression"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_char.webp"
              alt="Character Progression"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Combat Basics"
          link="/arknights-endfield/guides/combat-basics"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_combat.webp"
              alt="Combat Basics"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Damage Formula"
          link="/arknights-endfield/guides/damage-formula"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_damage.jpg"
              alt="Damage Formula"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Elements & Reactions"
          link="/arknights-endfield/guides/elements-and-reactions"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_elements.webp"
              alt="Elements & Reactions"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Gacha System"
          link="/arknights-endfield/guides/gacha-system"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_gacha.jpg"
              alt="Gacha System"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Gear System"
          link="/arknights-endfield/guides/gear-system"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_gearex.jpg"
              alt="Gear System"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Weapons System"
          link="/arknights-endfield/guides/weapons-system"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_weapons.jpg"
              alt="Weapons System"
            />
          }
          isWIP
        />
      </Row>
      <SectionHeader title="Database" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Characters"
          link="/arknights-endfield/characters"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_characters.jpg"
              alt="Characters"
            />
          }
          isNew
        />
        <CategoryCard
          title="Weapons"
          link="/arknights-endfield/weapons"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_weapons.webp"
              alt="Weapons"
            />
          }
          isNew
        />
        <CategoryCard
          title="Gear"
          link="/arknights-endfield/gear"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_gears.jpg"
              alt="Gears"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Tier List"
          link="/arknights-endfield/tier-list"
          image={
            <StaticImage
              src="../../../images/endfield/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
          isWIP
        />
      </Row>
    </DashboardLayout>
  );
};

export default EndfieldGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Arknights: Endfield | Prydwen Institute"
    description="Best guides for Arknights: Endfield that will help you understand the game better."
    game="endfield"
  />
);
